import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { svgTwitter } from '@/assets/icons';
import { Avatar, Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button } from '../button';
import { TextArea } from '../textfield';

const TWEET_BUTTON_ICON = svgTwitter;

type UserProps = {
  username: string;
  imageSrc: string;
};

export type ShareOnTwitterProps = StyleProps &
  UserProps & {
    defaultValue?: string;
  };

type TweetButtonProps = {
  href: string;
};

const TweetButton = (props: TweetButtonProps) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={props.href}
      className={el`button-link`}
    >
      <Button
        variant="secondary"
        startIcon={TWEET_BUTTON_ICON}
        startIconViewBoxWidth={24}
        className={el`button`}
      >
        TWEET
      </Button>
    </a>
  );
};

const ROOT = makeRootClassName('ShareOnTwitter');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ShareOnTwitter(props: ShareOnTwitterProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [tweet, setTweet] = React.useState(p.defaultValue || '');
  const encodedTweet = encodeURIComponent(tweet);
  const dynamicLink = `https://twitter.com/intent/tweet?text=${encodedTweet}`;

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="h3">Share on Twitter</Text>
      <div className={el`body`}>
        <div className={el`avatar-container`}>
          <Avatar image={p.imageSrc} name={p.username} size="large" />
        </div>
        <div className={el`tweet-box`}>
          <TextArea
            className="w-full"
            value={tweet}
            onChange={(value) => setTweet(value)}
            variant="transparent-borderless"
            autoresizes={true}
            aria-label="Share on Twitter"
          />
          <div className={el`button-wrapper`}>
            <Text type="body-sm" className="text-gray-800">
              Click the button to edit in Twitter
            </Text>
            <TweetButton href={dynamicLink} />
          </div>
        </div>
      </div>
      <div className={el`mobile-button-wrapper`}>
        <TweetButton href={dynamicLink} />
      </div>
    </div>
  );
}

export default ShareOnTwitter;
